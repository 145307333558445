<template>
    <router-view></router-view>
</template>

<script>
    import routeStoreModule from './routeStoreModule';
    import store from '@/store';
    import { onUnmounted } from '@vue/composition-api';

    export default {
        setup() {
            const ROUTE_APP_STORE_MODULE_NAME = 'app-routes';

            // Register module
            if (!store.hasModule(ROUTE_APP_STORE_MODULE_NAME))
                store.registerModule(ROUTE_APP_STORE_MODULE_NAME, routeStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(ROUTE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROUTE_APP_STORE_MODULE_NAME);
            });
        },
    };
</script>
